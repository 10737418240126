<div [@routerTransition] class="background">
    <div class="d-flex align-items-center justify-content-center w-100 header-container">
    </div>
    <div class="mb-40 overlay d-flex justify-content-center">
        <div class="thankyou-box justify-content-center align-items-center text-center">
            <div class="mb-5">
                <div class="fa fa-check-circle" [ngClass]="aPayContent ? 'checkmark-apay' : 'checkmark'"></div>
                <div class="gray-text thank-you-text">{{l('ThankYouOrderEmail')}}</div>
                <div class="gray-text copy-sent-text">{{l('ACopyOfYourReceiptWillBeSentTo')}} {{customerEmailAddressInput}}</div>
            </div>
            <div class="d-flex align-items-center justify-content-center pt-10 mb-5">
                <div class="row d-flex share-buttons-container">
                    <div class="col-4">
                        <div class="share-buttons-message">{{l('ShareToHelpUs')}}</div>
                    </div>
                    <div class="col-8 pl-0 share-buttons-custom align-items-center d-flex justify-content-center m-0">
                        <share-buttons theme="circles-dark" [include]="appListing" [title]="shareTitle" [showText]="true"
                                       [description]="shareDescription" [url]="shareUrl">
                        </share-buttons>
                        <share-buttons theme="circles-dark" [include]="appListingSms" [title]="shareTitle" [showText]="true"
                                       [description]="shareDescription" [url]="shareUrlSms">
                        </share-buttons>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <button class="checkout-button donation-option px-10 mt-10" (click)="returnToFundraiserHome()">
                            {{isLaunchathon ? l("MakeAnotherDonation") : l("PlaceAnotherOrder")}}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="showMessageContainer" class="w-100 d-flex justify-content-center" >
                <div class="p-10 mx-10 message-container">
                    <div class="want-do-leave-message-header">{{l('WouldYouLikeToLeaveAMessage')}}</div>
                    <div class="col-12">
                        <input type="text" [(ngModel)]="customerMessage" placeholder="Type here" name="donationMessageInput"
                               class="message-input" data-lpignore="true" maxlength="160" />
                    </div>

                    <div class="keep-it-clean">{{l('KeepItClean')}}</div>

                    <div class="justify-content-center align-items-center d-flex flex-row">
                        <div class="w-100 px-2 justify-content-center" (click)="setOrderMessage(true)">
                            <button class="w-100 customer-button customer-submit-button donation-option mx-2" [disabled]="!this.customerMessage"
                                    [ngClass]="this.customerMessage?.length > 0 ? 'enabled-background' : 'disabled-background'">
                                {{l('Submit')}}
                            </button>
                        </div>
                        <div class="justify-content-center w-100 px-2" (click)="setOrderMessage(false)">
                            <button class="w-100 customer-button customer-submit-button donation-option enabled-background  mx-2">
                                {{l('NoThanks')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border-top" *ngIf="aPayContent">
                <div class="aPayContent">
                    <p [innerHTML]="aPayContent"></p>
                </div>
                <div class="video-wrap">
                    <div class="video">
                        <p [innerHTML]="aPayEmbeddedVideo | safe: 'html'"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
