

<img src="/assets/common/images/help_tape.png" width="300" />

<div class="brush-stroke help-me-text">{{isFundraiserOver ? l('EndOfFundraiserResults') : fundraiserUserId ? l('HelpMeReachMyGoal') : l('HelpUsReachOurGoal')}}</div>

<div *ngIf="!isFundraiserOver && configHours && configMinutes" class="d-flex align-items-center justify-content-center">
    <div class="d-flex countdown-timer-container">
        <div class="countdown-unit-container">
            <div class="countdown-days-number">{{formattedDaysLeft}}</div>
            <div class="countdown-label">{{dayVsDays}} {{l('Left')}}</div>
        </div>
        <div class="countdown-unit-container">
            <countdown #cdHours [config]="configHours" (event)="handleEvent($event)"></countdown>
            <div class="countdown-label">{{l('HoursLeft')}}</div>
        </div>
        <div class="countdown-unit-container">
            <countdown #cdMinutes [config]="configMinutes" (event)="handleEvent($event)"></countdown>
            <div class="countdown-label">{{l('MinutesLeft')}}</div>
        </div>
    </div>
</div>

<div *ngIf="isFundraiserOver" class="d-flex align-items-center justify-content-center pt-5 pb-3">
    <div class="d-flex countdown-timer-container align-items-center justify-content-center">
        <div *ngIf="fundraiserUserId" class="countdown-unit-container">
            <div class="countdown-label final-raised-label brush-stroke">{{l('Total')}} {{studentName.split(' ')[0]}} {{isMetricDollars ? l('Raised') : l('Sold')}}</div>
            <div class="final-raised-amount">{{isMetricDollars ? (studentRaised  | currency:'USD':'symbol':'1.0-0') : studentRaised}}</div>
        </div>
        <div class="countdown-unit-container">
            <div class="countdown-label final-raised-label brush-stroke">{{isMetricDollars ? l('TotalTeamRaised') : l('TotalTeamSold')}}</div>
            <div class="final-raised-amount">{{isMetricDollars ? (teamRaised  | currency:'USD':'symbol':'1.0-0') : teamRaised}}</div>
        </div>
    </div>
</div>

<div *ngIf="!isFundraiserOver" class="please-and-thankyou px-10 px-sm-20">
    {{l('HelpMeReachMyGoalEtc')}}
    <span class="attribution">-{{studentName ? studentName : organizationName}}</span>
</div>
<div *ngIf="isFundraiserOver" class="please-and-thankyou px-10 px-sm-20">
    {{l('EndOfFundraiserNote')}}
    <span class="attribution">-{{organizationName}}</span>
</div>

<div class="d-flex align-items-center justify-content-center pt-10 px-5">
    <div class="row d-flex share-buttons-container">
        <div class="col-sm-4">
            <div class="share-buttons-message">{{isFundraiserOver ? l('ShareOurResults') : l('ShareThisPage')}}</div>
        </div>
        <div class="col-sm-8 pl-0 share-buttons-custom align-items-center d-flex justify-content-center m-0">
            <share-buttons theme="circles-dark" [include]="appListing" [title]="shareTitle" [showText]="true" 
                           [description]="shareDescription" [url]="shareUrl">
            </share-buttons>
            <share-buttons theme="circles-dark" [include]="appListingSms" [title]="shareTitle" [showText]="true"
                           [description]="shareDescription" [url]="shareUrlSms">
            </share-buttons>
        </div>
    </div>
</div>