<div [@routerTransition] class="bg-white">
    <form #orderForm="ngForm" novalidate autocomplete="off" (ngSubmit)="save()" class="w-100">
        <div class="h-100 w-100 bg-white">
            <!-- Checkout Header -->
            <fundraiser-header class="d-flex align-items-center justify-content-center w-100 header-container"
                [organizationName]="organizationName" [fundraiserName]="fundraiserName"
                [tenantLogoUrl]="tenantLogoUrl" [logoUrl]="logoUrl" [teamMemberNameInput]="teamMemberNameInput" [isCheckout]="true">
            </fundraiser-header>
            <!-- Begin Checkout-->
            <div class="overlay bg-white">
                <!-- Saving / Processing Order -->
                <div *ngIf="isProcessingOrder">
                    <div class="text-center p-5">
                        <h2 class="heading-white">{{l('PleaseWait')}}</h2>
                        <img class="busy-dots" alt="Order is being processed" src="/assets/common/images/busy-dots.gif" />
                        <p class="processing-text">{{l('OrderIsBeingProcessed')}}</p>
                        <p class="processing-text">{{l('DontRefresh')}}</p>
                        <div id="processing-order-spinner"></div>
                    </div>
                </div>
                <div class="container" [hidden]="isProcessingOrder">
                    <div class="mt-5" *ngIf="canUseApplePay">
                        <alert type="info" #applePayAlert>
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="/assets/common/images/apple_pay_icon.svg" alt="Apple Pay Icon" class="mh-45px me-5"> 
                                <h4 class="mb-0 text-center border-end border-dark pe-5"><strong>{{l('NowAcceptingApplePay')}}</strong></h4>
                                <span class="text-center ps-5">{{l('NowAcceptingApplePayDescription')}}</span>
                            </div>
                        </alert>
                    </div>
                    <h2 class="headline fs-4x fw-boldest mt-2">{{l('CheckOut')}}</h2>
                    <div class="row mx-3 border-1 justify-content-center border-bottom pb-3" *ngIf="!isLaunchathon">
                        <!-- Product Order Details: For some reason this code refuses to work in another component, so here is where it will live I guess -->
                        <div class="pb-2 mb-3 border-1 border-bottom border-light mw-1000px" *ngIf="selectedProductOrders && selectedProductOrders.length > 0">
                            <div *ngFor="let productOrder of selectedProductOrders; let i = index" [attr.data-index]="i" [style.background-color]="i % 2 == 0 ? 'white' : '#ebf8fa'">
                                <div class="row product-summary-row" *ngIf="productOrder.quantity >= 1">
                                    <div class="col-1 m--align-right align-self-center">
                                        <i class="remove-product-icon fa fa-times product-remove" title="Remove Product" (click)="removeProduct(productOrder)"></i>
                                    </div>
                                    <div class="col-5 col-sm-6 m--align-left item-description">
                                        {{productOrder.productName}}
                                        <span *ngIf="productOrder.productOptionId" class="word-break-word">{{productOrder.productOptionName}}</span>
                                    </div>
                                    <div class="col-4 col-sm-3 text-center align-self-center">
                                        <div class="product-increment-decrement">
                                            <i class="product-quantity-decrement fa fa-minus-circle" (click)="decrementQuantity(productOrder)"></i>
                                            <div class="item-quantity">{{productOrder.quantity}}</div>
                                            <i class="product-quantity-increment fa fa-plus-circle"(click)="incrementQuantity(productOrder)"></i>
                                        </div>
                                    </div>
                                    <div class="col-2 col-sm-2 m--align-right align-self-center item-total p-0">
                                        <div class="order-summary-item-right-side">
                                            {{ productOrder.quantity * productOrder.price | currency: 'USD' : 'symbol' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="orderTotalsUpdateResult?.discountTotal && orderTotalsUpdateResult?.discountTotal > 0" class="d-flex justify-content-between mw-1000px">
                            <span>{{l("QuantityDiscountLabel")}}</span>
                            <span class="order-summary-right-side">
                                -{{orderTotalsUpdateResult?.discountTotal | currency:'USD':'symbol':'1.2-2'}}
                            </span>
                        </div>
                        <div *ngIf="orderDetail.directShip" class="d-flex justify-content-between mw-1000px">
                            <span>{{l("ShippingFeeLabel")}}</span>
                            <div class="order-summary-right-side">
                                {{orderTotalsUpdateResult?.shippingFee | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                        <div *ngIf="(!isDonationOnly && acceptDonations)" class="d-flex justify-content-between mw-1000px">
                            <span>{{l("Donation")}}</span>
                            <div class="order-summary-right-side">
                                {{orderTotalsUpdateResult?.donationTotal | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                        <div *ngIf="this.guestPayMode == this.PAY_CC && orderTotalsUpdateResult?.convenienceFee > 0" class="d-flex justify-content-between mw-1000px">
                            <span>{{l("ConvenienceFeeLabel", convenienceFee)}}</span>
                            <div class="order-summary-right-side">
                                {{orderTotalsUpdateResult?.convenienceFee | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between order-info-total mw-1000px">
                            <span class="fs-2 fw-boldest">{{l("Total")}}</span>
                            <div class="order-summary-right-side fs-2 fw-boldest">
                                {{orderTotalsUpdateResult?.orderTotal + checkoutOrderDetails?.tipDollars | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                        <!-- end Product Order Details -->
                    </div>
                    <div class="row" *ngIf="showCheckoutDetails; else showEmpty">
                        <!-- using ngx-bootstrap accordion -->
                        <accordion [isAnimated]="true" [closeOthers]="true">
                            <accordion-group panelClass="d-flex" [isOpen]="isShippingDetailsOpen" (isOpenChange)="goToShippingDetails($event)">
                                <button accordion-heading type="button" 
                                    class="btn btn-flush btn-block justify-content-between d-flex w-100 fw-bold text-dark">
                                    <span class="pull-left float-left">{{l("Step")}} 1 - {{l("OrderDetailsFull")}} </span>
                                    <span class="float-right pull-right">
                                        <i [ngClass]="isShippingDetailsOpen ? 'fa-minus-circle' : 'fa-plus-circle'" class="fa text-success fs-3"></i>
                                    </span>
                                </button>
                                <!-- Checkout Order Details -->
                                <checkout-order-details #checkoutOrderDetails [orderDetail]="orderDetail" [isGuest]="isGuest"
                                    [directShipAvailable]="directShipAvailable" [orderTotalsUpdateResult]="orderTotalsUpdateResult" [forceDirectShip]="forceDirectShip"
                                    [paymentMethods]="paymentMethods" [states]="states" [orderContainsDGCSelection]="orderContainsDGCSelection" [teamMemberNameInput]="teamMemberNameInput"
                                    [teamMemberId]="teamMemberIdInput" [availableSellers]="availableSellers" [acceptDonations]="acceptDonations" [isDonationOnly]="isDonationOnly"
                                    [isLaunchathon]="isLaunchathon" [donationAmountInput]="donationAmountInput" [donateByRoundingOrderTo]="donateByRoundingOrderTo"
                                    [tipInfoTitle]="tipInfoTitle" [tipInfo]="tipInfo" [orderTotal]="orderTotalsUpdateResult?.orderTotal"
                                    (updateTotalsOutput)="updateTotals()" (updateShipOptionOutput)="updateShipOption($event)" [automatedMessageType]="automatedMessageType"
                                    (goToPaymentMethodsOutput)="goToPaymentInfo(true, true)" (updateDonateByRoundingOrderTo)="donateByRoundingOrderTo = $event">
                                </checkout-order-details>
                            </accordion-group>
                            <accordion-group panelClass="d-flex" [isOpen]="isPaymentMethodsOpen" [isDisabled]="isPaymentMethodsDisabled" (isOpenChange)="goToPaymentInfo($event)">
                                <button accordion-heading type="button" 
                                    class="btn btn-flush btn-block justify-content-between d-flex w-100 fw-bold text-dark">
                                    <span class="pull-left float-left">{{l("Step")}} 2 - {{l("PaymentInfo")}}</span>
                                    <span class="float-right pull-right">
                                        <i [ngClass]="[isPaymentMethodsOpen ? 'fa-minus-circle' : 'fa-plus-circle',
                                                        isPaymentMethodsDisabled ? 'text-dark opacity-75' : 'text-success']" class="fa fs-3"></i>
                                    </span>
                                </button>
                                <!-- Checkout Payment Methods -->
                                <checkout-payment-methods #checkoutPaymentMethods [isGuest]="isGuest" [orderDetail]="orderDetail" [paymentMethods]="paymentMethods"
                                    [checksPayableName]="checksPayableName" [creditCardText]="creditCardText" [studentCreditCardOnly]="studentCreditCardOnly" [isAProductSelected]="isAProductSelected"
                                    [shareLink]="shareLink" [orderTotalsUpdateResult]="orderTotalsUpdateResult" [fundraiserUserId]="fundraiserUserIdInput" [fundraiserId]="fundraiserIdInput"
                                    [tipDollars]="checkoutOrderDetails?.tipDollars" [isLaunchathon]="isLaunchathon" [isApplePayEnabledForFundraiser]="isApplePayEnabledForFundraiser"
                                    (sendSharedOrderOutput)="sendSharedOrder()" (goToReviewOrderOutput)="goToReviewOrder(true)" (updatePaymentMethod)="updatePaymentMethodEmitter($event)">
                                </checkout-payment-methods>
                            </accordion-group>
                            <accordion-group panelClass="d-flex" [isOpen]="isReviewOrderOpen" [isDisabled]="isReviewOrderDisabled" (isOpenChange)="goToReviewOrder($event)">
                                <button accordion-heading type="button" 
                                    class="btn btn-flush btn-block justify-content-between d-flex w-100 fw-bold text-dark">
                                    <span class="pull-left float-left">{{l("Step")}} 3 - {{l("ReviewOrder")}}</span>
                                    <span class="float-right pull-right">
                                        <i [ngClass]="[isReviewOrderOpen ? 'fa-minus-circle' : 'fa-plus-circle',
                                                        isReviewOrderDisabled ? 'text-dark opacity-75' : 'text-success']" class="fa fs-3"></i>
                                    </span>
                                </button>
                                <!-- Checkout Review Order -->
                                <checkout-review-order #checkoutReviewOrder [orderDetail]="orderDetail" [state]="stateName" [isLaunchathon]="isLaunchathon"
                                    [isDonationOnly]="isDonationOnly" [paymentMethodName]="paymentMethodName" [isGuest]="isGuest" [paymentMethods]="paymentMethods"
                                    [isUpdatingTotals]="isUpdatingTotals" [totalAmount]="orderTotalsUpdateResult?.orderTotal" [organizationName]="organizationName" 
                                    [isApplePayEnabledForFundraiser]="isApplePayEnabledForFundraiser" (completeCheckOutOrderOutput)="save()" (applePayEvent)="saveWithApplePay($event)">
                                </checkout-review-order>
                            </accordion-group>
                        </accordion>
                    </div>
                    <ng-template #showEmpty>
                        <div class="row mx-3 border-1 justify-content-center border-bottom py-5">
                            <div class="col-12 text-center">
                                <h3>{{l('EmptyOrderNoDonation')}}</h3>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Rep Info: For Students who are logged in on a Product Fundraiser -->
                    <div *ngIf="!isDonationOnly && !isGuest && repInfo != undefined" class="row d-flex justify-content-center mt-8 mb-5 text-center repinfo">
                        <h3 class="w-75">
                            Rep Contact Info: 
                            <span *ngIf="repInfo.fullName">{{repInfo.fullName}}</span>
                            <span *ngIf="repInfo.emailAddress"> | {{repInfo.emailAddress}}</span>
                            <span *ngIf="repInfo.phoneNumber"> | {{repInfo.phoneNumber | phone}}</span>
                        </h3>
                    </div>
                    <!-- Back Button-->
                    <div class="d-flex align-items-center justify-content-center mb-1 mt-3">
                        <button *ngIf="!(orderDetailId && isLaunchathon)" type="button" class="customer-button customer-cancel-button m-0" (click)="continueShopping()">
                            {{ l("Back") }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- Fundraiser Footer -->
            <footer class="live-link-footer">
                <fundraiser-footer [tenantLogoUrl]="tenantLogoUrl" [isDarkBackground]="true"></fundraiser-footer>
            </footer>
        </div>
    </form>
</div>
