import { Component, HostListener, Inject, Injector, NgZone, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CreateOrEditOrderDetailDto, CreateOrEditProductOptionDto, CreditCardDto, FundraiserProductGroupDto, FundraisersServiceProxy,
    GetFundraiserUserInfo, ListItemLookupTableDto, OrderProductOrPackageDto, OrderTotalsDto,
    OrderTotalsInput, ProductGroupDto, RepInfoDto, RepServiceProxy, SharedOrdersServiceProxy
} from '../../../../shared/service-proxies/service-proxies';
import { CountdownConfig } from 'ngx-countdown';
import { CheckoutComponent, OrderDetailPlusProductAndPackageViewModels } from '../checkout/checkout.component';
import { OrderOptionsComponent } from '../../launch/orders/order-components/order-options.component';
import { HelpReachGoalInfo } from '../help-reach-goal/help-reach-goal.component';
import { DonationsPageComponent } from '../../launch/orders/donations-page/donations-page.component';
import { GoalNumbers, ProductOrPackageViewModel } from '../fundraiser-home/fundraiser-home.component';
import { CompleteOrderInfo } from '../checkout/checkout.component';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { ItemImageViewerComponent } from '../item-image-viewer/item-image-viewer.component';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './product-fundraiser-home.component.html',
    styleUrls: ['./product-fundraiser-home.component.less'],
    animations: [appModuleAnimation()]
})
export class ProductFundraiserHomeComponent extends AppComponentBase {
    @ViewChild('checkout', { static: true }) comparisonFundraiserLookupTableModal: CheckoutComponent;
    @ViewChild('orderOptionsView', { static: false }) orderOptionsView: OrderOptionsComponent;
    @ViewChild('donationView', { static: false }) donationView: DonationsPageComponent;
    @ViewChild('itemImageViewerModal', { static: false }) itemImageViewerModal: ItemImageViewerComponent;

    readonly PAY_INPERSON = 0;
    readonly PAY_TEXTCC = 1;
    readonly PAY_CC = 2;

    readonly scrollAmountBeforeShowingDonateButton: number = 500;
    readonly maximumMobileHeight: number = 900;

    fundraiserUserId: number;
    configHours: CountdownConfig = {};
    configMinutes: CountdownConfig = {};
    daysLeft: string;
    get dayVsDays(): string {
        let result = 'Days';
        if (this.daysLeft == '1') {
            result = 'Day'
        }
        return result;
    }
    donationOptions = [50, 100, 150, 250, 500];

    appListing: string[] = ['facebook', 'twitter', 'email'];
    appListingSms: string[] = ['sms'];
    shareTitle: string;
    shareDescription: string;
    shareUrl: string;
    shareUrlSms: string = '';

    teamMemberId: number;

    tenantId: number;
    isApplePayEnabledForFundraiser: boolean = false;


    productsAndPackages: ProductOrPackageViewModel[] = [];
    productGroup: ProductGroupDto = new ProductGroupDto();
    productOptions: { [id: number]: CreateOrEditProductOptionDto } = {};
    sharedLinkMessage = '';
    fundraiserEndDate = '';
    orderDetail: CreateOrEditOrderDetailDto = new CreateOrEditOrderDetailDto();
    isActive = false;
    guestPayMode = this.PAY_INPERSON;
    donationRoundUpProblem = false;
    orderTotals: OrderTotalsDto = new OrderTotalsDto;
    orderOptionsProduct: ProductOrPackageViewModel = undefined;

    allowOrderDonationButton = false;
    showOptionsPage = false;

    helpReachGoalInfo: HelpReachGoalInfo;
    showDonationPage: boolean;

    defaultOrderDetailToDirectShip: boolean;

    fundraiserStudentContactSlug: string;
    automatedMessageType: string;

    // these items only need to be calculated once, so this will help with performance
    _effectiveStudentGoal: number;
    get effectiveStudentGoal(): number {
        return this._effectiveStudentGoal ?? (this._effectiveStudentGoal = this.getEffectiveGoal(this.studentRaised, this.studentGoal, this.studentStretchIncrement));
    }

    _effectiveTeamGoal: number;
    get effectiveTeamGoal(): number {
        return this._effectiveTeamGoal ?? (this._effectiveTeamGoal = this.getEffectiveGoal(this.teamRaised, this.teamGoal, this.teamStretchIncrement));
    }

    _teamStretchIncrement: number;
    get teamStretchIncrement(): number {
        return this._teamStretchIncrement ?? (this._teamStretchIncrement = this.calculateTeamStretchIncrement());
    }

    private calculateTeamStretchIncrement(): number {
        if (!this.fundraiserUserInfo)
            return null;

        return this.fundraiserUserInfo.isMetricDollars 
            ? AppConsts.teamStretchIncrementDollars 
            : AppConsts.teamStretchIncrementUnits;
    }

    // this only needs to be calculated once
    _studentStretchIncrement: number;
    get studentStretchIncrement(): number {
        return this._studentStretchIncrement ?? (this._studentStretchIncrement = this.calculateStudentStretchIncrement());
    }

    private calculateStudentStretchIncrement(): number {
        if (!this.fundraiserUserInfo)
            return null;

        return this.fundraiserUserInfo.isMetricDollars 
            ? AppConsts.studentStretchIncrementDollars 
            : AppConsts.studentStretchIncrementUnits;
    }

    private getEffectiveGoal(raised: number, goal: number, stretchIncrement: number): number {
        if (!this.fundraiserUserInfo)
            return null;

        // `goal` is the `goal`
        //  UNLESS ...
        //      `goal` has been exceeded 
        //          OR
        //      `viewSample` is true
        //  THEN ...
        //      `goal` is the next `stretchIncrement` past the goal
        if (this.viewSample || raised < goal)
            return goal;

        return (Math.trunc((raised - goal) / stretchIncrement) + 1) 
            * stretchIncrement 
            + goal;
    }

    fundraiserUserInfo: GetFundraiserUserInfo;
    get teamGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamGoal.toFixed();
    }
    get teamGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.effectiveTeamGoal ? (this.teamRaised / this.effectiveTeamGoal * 100).toString() + '%' : '100%';
    }
    get teamGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.teamGoal ? (this.teamRaised / this.teamGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.effectiveStudentGoal ? (this.studentRaised / this.effectiveStudentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.studentGoal ? (this.studentRaised / this.studentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalRemaining() {
        if (!this.fundraiserUserInfo) return 0;
        return this.effectiveStudentGoal > this.studentRaised ? this.effectiveStudentGoal - this.studentRaised : 0;
    }
    get teamRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamRaised.toFixed();
    }
    get studentRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentRaised.toFixed();
    }
    get studentGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentGoal.toFixed();
    }

    customerEmailAddress: string;

    slugParam: string;
    orderDetailId: number;
    isInitialLoadForEdit: boolean = true;
    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy,
        private _router: Router,
        public _zone: NgZone,
        private _repService: RepServiceProxy,
        private _renderer2: Renderer2,
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: Document
    ) {
        super(injector);

        this._activatedRoute.params.subscribe(params => {
            this.orderDetailId = params.orderId;
            this.fundraiserUserId = params.fundraiserUserId;
            this.slugParam = params.slug;

            this._activatedRoute.queryParams.subscribe(qparams => {
                this.fundraiserStudentContactSlug = qparams['fscSlug'];
                this.automatedMessageType = qparams['automatedMessageType'];

                this.getFundraiserUserInfo();
            });
        });
    }

    viewSample: boolean;
    ngOnInit(): void {
        this.viewSample = window.location.pathname.includes('viewSample');
        this.orderDetail.customDonation = 0;        
    }

    viewHeight = 0;
    ngAfterViewInit():void {
        this.viewHeight = window.innerHeight;
    }

    userHasScrolled = false;
    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event) {
        if (window.pageYOffset > this.scrollAmountBeforeShowingDonateButton) {
            this.userHasScrolled = true;
        }
    }

    paymentMethods: ListItemLookupTableDto[] = [];
    checksPayableName = '';
    creditCardText = '';
    convenienceFee = 5;
    directShipAvailable: boolean = false;
    forceDirectShip: boolean = false;
    acceptDonations = false;
    guestPayCCOption: number;
    studentCreditCardOnly = false;
    repInfo: RepInfoDto;
    isDonationOnly: boolean = false;

    get showPaymentOptions(): boolean {
        return !this.fundraiserUserInfo.isGuest && !this.isDonationOnly;
    }

    loadFundraiserData(): void {
        if (!this.fundraiserUserInfo.isGuest) {
            this._repService.getRepInfoByFundraiser(this.fundraiserUserInfo.fundraiserId).subscribe(result => {
                this.repInfo = result;
            });
        }

        this._sharedOrdersServiceProxy.getFundraiserPaymentMethods(this.fundraiserUserInfo.fundraiserId).subscribe(result => {
            if (!result || !result.paymentMethods) {
                this._router.navigate(['app', 'main', 'fundraiser']); // user didn't have access to this fundraiser
                return;
            }
            this.paymentMethods = result.paymentMethods;
            this.checksPayableName = result.checksPayableName;
            this.creditCardText = result.creditCardText;
            this.convenienceFee = result.convenienceFee;
            this.directShipAvailable = result.directShipAvailable;
            this.forceDirectShip = result.forceDirectShip;
            this.acceptDonations = result.acceptDonations;
            this.donationOptions = result.donationOptions;

            if (this.fundraiserUserInfo.isGuest) {
                if (result.enableCreditCardPayment) {
                    this.guestPayMode = this.PAY_CC;
                    this.guestPayCCOption = this.PAY_CC;
                    this.orderDetail.creditCardInfo = new CreditCardDto();
                } else if (this.creditCardText && this.creditCardText.trim() !== '') {
                    this.guestPayMode = this.PAY_TEXTCC;
                    this.guestPayCCOption = this.PAY_TEXTCC;
                } else
                    this.guestPayMode = this.PAY_INPERSON;
            }
            // now that we have guestPayMode, updateTotals to get convenience fee:
            this.updateTotals();

            if (!this.fundraiserUserInfo.isGuest && result.isCreditCardOnly && !(this.orderDetail.id > 0))
                this.studentCreditCardOnly = true;

            if (!this.showPaymentOptions) {
                // the only payment option is CC:
                this.orderDetail.paymentMethodId = this.paymentMethods.find(pm => pm.abbreviation == 'cc')?.id;
            }
            else if (!this.orderDetail.paymentMethodId && this.paymentMethods.length > 0) {
                this.orderDetail.paymentMethodId = this.paymentMethods[0].id;
            }

            if (this.forceDirectShip) {
                this.orderDetail.directShip = true;
                this.guestPayMode = this.guestPayCCOption;
            }

            if (this.orderDetailId) {
                this.submit();
            }
        },
            (error) => { this._router.navigate(['app', 'main', 'fundraiser']); });
    }

    getFundraiserUserInfo() {
        this._fundraisersServiceProxy.getFundraiserUserInfo(this.fundraiserUserId, this.slugParam, this.orderDetailId).subscribe(result => {
            this.fundraiserUserInfo = result;
            this.tenantId = result.tenantId;
            this.isApplePayEnabledForFundraiser = this.fundraiserUserInfo.enableApplePay;


            if (this.fundraiserUserId && this.appSession.user?.id == this.fundraiserUserInfo.studentId) {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsStudentShareMessage ?
                    this.fundraiserUserInfo.smsStudentShareMessage : this.l('DefaultStudentShareMessage'));
            }
            else {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsShareMessage ?
                    this.fundraiserUserInfo.smsShareMessage : this.l('OrderHelpFundraiser'));
            }

            this.shareTitle = `${this.fundraiserUserInfo.organizationName} - ${this.fundraiserUserInfo.fundraiserName}`;

            this.teamMemberId = this.fundraiserUserInfo.studentId;

            this.renderProductOptions(result.fundraiserProductGroupDto);

            this.allowOrderDonationButton = result.allowOrderDonationButton;

            this.helpReachGoalInfo = new HelpReachGoalInfo(this.fundraiserUserInfo.isFundraiserEndUtc, this.fundraiserUserInfo.fundraiserEnd);

            this.setIsFundraiserOver();
            if (this.isFundraiserOver) {
                this.insertTrackingTagScript();
            }


            if (this.fundraiserUserId) {
                this.setCarouselGoalComponents();
            }

            this.shareUrl = this.getShareLink();
            this.shareUrlSms = this.getSmsVersionOfShareUrl(this.shareUrl);

            if (this.fundraiserUserInfo.isGuest && result.directShipOption == 20){
                // this must be true for force-all-orders
                this.defaultOrderDetailToDirectShip = true;
                this.orderDetail.directShip = true;
            }

            this.loadFundraiserData();
        });
    }

    insertTrackingTagScript() {
        let script = this._renderer2.createElement('script');
        script.text = `
            {
                gtag('event', 'ended_pageview', {
                    'fundraiser_ID': '` + this.fundraiserUserInfo.fundraiserId + `',
                  });
            }
        `;
        this._renderer2.appendChild(this._document.body, script);
    }

    getSecondsLeftInFundraiser(now: DateTime = null) {
        let nowUtcSeconds: number;
        let offset: number;

        if (this.fundraiserUserInfo.fundraiserEnd == null) {
            return null;
        }
        if (now == null) {
            now = DateTime.now();
        }
        nowUtcSeconds = now.toSeconds();
        // if !isFundraiserEndUtc, assume client is in same timezone as fundraiser.
        offset = this.fundraiserUserInfo.isFundraiserEndUtc ? 0 : now.offset * 60;
        // end date is stored in the DB with midnight (the start) of the day for time,
        // so add a day's worth of seconds (86400) to the result.
        let result = this.fundraiserUserInfo.fundraiserEnd - nowUtcSeconds - offset + 86400;
        return result;
    }
    setIsFundraiserOver(): void {
        let now = DateTime.now();
        this.isFundraiserOver = this.getSecondsLeftInFundraiser(now) < 0;
    }

    isFundraiserOver: boolean;

    getFilledInShareMessage(rawShareMessage: string): string {
        let result = rawShareMessage.replace('{STUDENTNAME}', this.fundraiserUserInfo.studentName)
            .replace('{ORGANIZATIONNAME}', this.fundraiserUserInfo.organizationName)
            .replace('{STUDENTGOAL}', this.effectiveStudentGoal.toString());
        return result;
    }

    reload(orderDetailPlusProductViewModels: OrderDetailPlusProductAndPackageViewModels): void {
        this.orderDetail = orderDetailPlusProductViewModels.orderDetail;
        this.isInitialLoadForEdit = false;
        this.productsAndPackages = orderDetailPlusProductViewModels.productAndPackageViewModels;
        this.donationAmount = this.orderDetail.donationAmount;
        this.isCheckout = false;
        this.showOptionsPage = false;
        this.updateOrderProducts();
    }

    renderProductOptions(result: FundraiserProductGroupDto) {
        if (!result) {
            this._router.navigate(['app', 'main', 'fundraiser']); // user didn't have access to this fundraiser
            return;
        }
        this.productsAndPackages = [];
        this.productOptions = {};
        result.productOptions.forEach(x => this.productOptions[x.id] = x);
        this.productGroup = result.productGroup;
        this.sharedLinkMessage = result.sharedLinkMessage;
        this.fundraiserEndDate = result.fundraiserEndDate;

        result.productsAndPackages.forEach((productOrPackage) => {
            if (productOrPackage.isActive) {
                var ppvm = new ProductOrPackageViewModel();
                ppvm.productDetails = productOrPackage;
                if (ppvm.productDetails && ppvm.productDetails.id) {
                    ppvm.orderProducts = [];
                    ppvm.productOptions = result.productOptions.filter(x => ppvm.productDetails?.productOptionIds?.indexOf(x.id) >= 0);

                    if (ppvm.productOptions && ppvm.productOptions.length > 0) {
                        for (let opt of ppvm.productOptions) {
                            let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage, opt.id);
                            ppvm.orderProducts.push(orderProduct);
                        }
                    } else {
                        let orderProduct = this.setupOrderProduct(ppvm.productDetails.id, ppvm.productDetails.isPackage, undefined);
                        ppvm.orderProducts.push(orderProduct);
                    }

                    this.productsAndPackages.push(ppvm);
                }
            }
        });
    }

    async updateTotals(isDonate: boolean = false) {
        this.updateOrderProducts();

        let input = new OrderTotalsInput();
        input.fundraiserId = this.fundraiserUserInfo.fundraiserId;
        input.creditCardPayment = this.guestPayMode == this.PAY_CC;
        input.directShip = this.orderDetail.directShip;
        input.fundraiserId = this.fundraiserUserInfo.fundraiserId;
        input.orderProductsAndPackages = this.orderDetail.orderProductsAndPackages;
        input.customDonation = this.orderDetail.customDonation;
        input.selectedDonation = this.orderDetail.selectedDonation;
        input.donateByRoundingOrderTo = this.donateByRoundingOrderTo;

        this._sharedOrdersServiceProxy.updateOrderTotals(false, input)
            .subscribe(result => {
                this.orderTotals = result;
                if (this.orderDetail.id) {
                    // user is editing an existing order. skip the transitional donation page,
                    // since they can edit donation and tip on the checkout page.
                    this.isCheckout = true;
                }
                else if (isDonate) {
                    this.navigateToFundraiserHomePageForDonations();
                }
            });
    }

    incrementQuantity(orderProduct: OrderProductOrPackageDto): void {
        orderProduct.quantity++;
        this.updateOrderProducts();
    }

    decrementQuantity(orderProduct: OrderProductOrPackageDto, allowZero: boolean = true): void {
        if (!allowZero && orderProduct.quantity === 1) {
            this.removeProduct(orderProduct);
        } else {
            if (orderProduct.quantity > 0)
                orderProduct.quantity--;
        }
        this.updateOrderProducts();
    }

    removeProduct(orderProduct: OrderProductOrPackageDto): void {
        this.message.confirm('',
            this.l('RemoveProductFromCart'),
            (isConfirmed) => {
                if (isConfirmed) {
                    orderProduct.quantity = 0;
                    this.updateOrderProducts();
                }
            });
    }

    itemsCountText: string = '';
    updateOrderProducts(): void {
        let totalItemsCount = 0;
        this.itemsCountText = '';
        this.orderDetail.orderProductsAndPackages = [];
        for (var k in this.productsAndPackages) {
            this.orderDetail.orderProductsAndPackages.push(...this.productsAndPackages[k].orderProducts);
            this.productsAndPackages[k].orderProducts.forEach(orderProduct => {
                totalItemsCount += orderProduct.quantity;
            });
        }
        if (totalItemsCount) {
            let itemsText = totalItemsCount > 1 ? 'items' : 'item';
            this.itemsCountText = `(${totalItemsCount} ${itemsText})`;
        }
    }

    showOptions(showProduct: ProductOrPackageViewModel) {
        this.orderOptionsView.show(showProduct.productDetails, showProduct.productOptions, showProduct.orderProducts)
        this.orderOptionsProduct = showProduct;
        this.showOptionsPage = true;
    }

    closeOptions() {
        this.orderOptionsProduct = undefined;
        this.showOptionsPage = false;
    }

    updateOrderOption(orderProduct: OrderProductOrPackageDto) {
        let p = this.productsAndPackages.find(x => x.productDetails.id === orderProduct.productOrPackageId);
        if (p) {
            let op = p.orderProducts.find(x => x.productOrPackageId === orderProduct.productOrPackageId && x.productOptionId === orderProduct.productOptionId);
            if (op) {
                op.quantity = orderProduct.quantity;
            }
        }
        this.updateOrderProducts();
    }


    proceedToCheckout(): void {
        window.scrollTo(0, 0);
        this.submit();
    }

    hasViewedDonationPage: boolean = false;

    async showDonationView() {
        this.showDonationPage = true;
        this.hasViewedDonationPage = true;
        this.donationView.show(this.donationOptions, this.orderTotals.orderTotal - this.orderTotals.donationTotal,
            this.orderDetail.customDonation, this.orderTotals.tipPercentage, this.orderDetail.id > 0, this.orderDetail.id != null);
    }

    updateDonation(values: {
        selectedDonation: number, customDonation: number, updatedTipPercentage: number,
        updatedTipDollars: number, goToCheckout: boolean
    }) {
        this.showDonationPage = false;
        this.orderDetail.selectedDonation = values.selectedDonation;
        this.orderDetail.customDonation = values.customDonation;
        this.orderDetail.tipPercentage = values.updatedTipPercentage;
        this.orderDetail.tipDollars = values.updatedTipDollars;
        this.donationRoundUpProblem = false;
        window.scrollTo(0, 0);

        if (values.goToCheckout) {
            this.submit();
        }
    }

    setupOrderProduct(productId: number, isPackage: boolean, productOptionId: number = undefined): OrderProductOrPackageDto {
        let orderProductOrPackage = new OrderProductOrPackageDto();
        orderProductOrPackage.productOrPackageId = productId;
        orderProductOrPackage.productOptionId = productOptionId;

        var quantity = 0;
        if (this.orderDetail.orderProductsAndPackages) {
            var op = this.orderDetail.orderProductsAndPackages.find(x => x.productOrPackageId == productId && x.productOptionId == productOptionId);
            if (op) {
                quantity = op.quantity;
            }
        }
        orderProductOrPackage.quantity = quantity;
        orderProductOrPackage.isPackage = isPackage;

        return orderProductOrPackage;
    }

    donationAmount: number;
    isCheckout: boolean;
    donateByRoundingOrderTo: number;
    async submit() {
        this.closeOptions();
        this.donateByRoundingOrderTo = this.orderDetail.selectedDonation ? this.orderDetail.selectedDonation : 0;
        // now we have to decide which one would have precedence if they both have values...
        // let's pick customDonation:
        if (this.orderDetail.customDonation) {
            this.donationAmount = this.orderDetail.customDonation;
        }
        else if (this.donateByRoundingOrderTo) {
            this.donationAmount = this.donateByRoundingOrderTo - this.orderTotals.productTotal;
        }
        else {
            this.donationAmount = 0;
        }

        this.updateTotals();

        this.isCheckout = true;
    }

    get isAProductSelected(): boolean {
        return this.orderDetail?.orderProductsAndPackages?.find(op => op.quantity > 0) != null;
    }

    isOrderComplete: boolean = false;
    orderTransactionId: string;
    orderComplete(orderInfo: CompleteOrderInfo) {
        this.isCheckout = false;
        this.isOrderComplete = true;
        this.orderTransactionId = orderInfo.orderTransactionId;
        this.customerEmailAddress = orderInfo.customerEmailAddress;
        this.fundraiserUserInfo.teamRaised += orderInfo.orderAmount;
        if (this.studentRaised && !Number.isNaN(this.studentRaised)) {
            this.fundraiserUserInfo.studentRaised += orderInfo.orderAmount;
        }
        this.cdr.detectChanges();
    }

    cancelOrder() {
        this.isCheckout = false;
    }

    returnFromConfirmation() {
        this.isCheckout = false;
        this.isOrderComplete = false;
        this.resetDonationSelections();
        this.getFundraiserUserInfo();
        this.itemsCountText = '';
    }

    resetDonationSelections() {
        this.donationAmount = undefined;
        this.orderDetail = new CreateOrEditOrderDetailDto();
        this.orderDetail.donationAmount = undefined;
        this.orderDetail.customDonation = undefined;
        this.orderDetail.selectedDonation = undefined;
        this.productsAndPackages.forEach(product => {
            product.orderProducts.forEach(orderProduct => {
                orderProduct.quantity = 0;
            });
        });
    }

    getShareLink(): string {
        let fundraiserUserId = this.fundraiserUserId ? this.fundraiserUserId : '0';
        let slug = this.fundraiserUserInfo.slug ? this.fundraiserUserInfo.slug : '';

        let link = `${this.appRootUrl()}FundraiserHomeInfo/${fundraiserUserId}/${slug}`;

        if (window.location.href.includes('localhost')) {
            link = link.replace('FundraiserHomeInfo', 'fundraiserHomeInfo');
        }
        return link;
    }

    async navigateToFundraiserHomePageForDonations() {
        if (this.isAProductSelected) {
            // navigate to the donate-transition page.
            this.showDonationView();
        }
        else {
            if(this.fundraiserStudentContactSlug && this.fundraiserStudentContactSlug.charAt(0) === AppConsts.FUNDRAISER_STUDENT_CONTACT_SLUG_PREFIX) {

                const queryParams = {
                    fscSlug: this.fundraiserStudentContactSlug
                };

                if (this.fundraiserUserId) {
                    this._router.navigate(['launchathonFundraiserHome', 'student', this.fundraiserUserId], { queryParams: queryParams });
                }
                else {
                    this._router.navigate(['launchathonFundraiserHome', 'fundraiser', this.fundraiserUserInfo.slug], { queryParams: queryParams });
                }
            }
            else
            {
                if (this.fundraiserUserId) {
                    this._router.navigate(['launchathonFundraiserHome', 'student', this.fundraiserUserId]);
                }
                else {
                    this._router.navigate(['launchathonFundraiserHome', 'fundraiser', this.fundraiserUserInfo.slug]);
                }
            }            
        }
    }

    navigateToCheckoutOrDonate() {
        if (!this.isAProductSelected) {
            this.message.warn(this.l('NoProductsSelected'));
            return;
        }

        if (this.allowOrderDonationButton) {
            // navigate to the donate-transition page.
            this.updateTotals(true);
        }
        else {
            this.submit();
        }
    }

    goalValues: GoalNumbers[] = [];
    setCarouselGoalComponents(): void {
        let totalRaisedLabel = this.fundraiserUserInfo.isMetricDollars ? this.l('TotalRaisedLabel') : this.l('TotalSoldLabel');
        let goalValuesFull: GoalNumbers[] = [];
        goalValuesFull.push({
            rightTitle: this.l('MyGoalLabel'),
            rightAmount: this.isFundraiserOver ? this.studentGoal : this.effectiveStudentGoal,
            leftTitle: totalRaisedLabel,
            leftAmount: this.studentRaised,
            progressBarValue: this.isFundraiserOver ? this.studentGoalPercentActual : this.studentGoalPercentEffective,
            name: this.fundraiserUserInfo.studentName
        })

        if (!this.isFundraiserOver) {
            goalValuesFull.push({
                rightTitle: this.l('TeamGoalLabel'),
                rightAmount: this.effectiveTeamGoal,
                leftTitle: totalRaisedLabel,
                leftAmount: this.teamRaised,
                progressBarValue: this.teamGoalPercentEffective,
                name: this.l('Team')
            });
        } else {
            goalValuesFull.push({
                rightTitle: this.l('TotalRaisedLabel'),
                rightAmount: this.teamRaised,
                leftTitle: totalRaisedLabel,
                leftAmount: this.teamGoal,
                progressBarValue: this.teamGoalPercentActual,
                name: this.l('Team')
            })
        }
        this.goalValues = goalValuesFull;
    }

    showItemViewer(imageUrl: string) {
        if (imageUrl) {
            this.itemImageViewerModal.show(imageUrl);
        }
    }
}