import { Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { SharedOrdersServiceProxy } from "@shared/service-proxies/service-proxies";
import { appModuleAnimation } from "../../../../shared/animations/routerTransition";
import { AppComponentBase } from "../../../../shared/common/app-component-base";

@Component({
    selector: 'order-confirmation',
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.less'],
    animations: [appModuleAnimation()]
})
export class OrderConfirmationComponent extends AppComponentBase {
    @Input() customerEmailAddressInput: string;

    @Input() appListing: string[];
    @Input() appListingSms: string[];
    @Input() shareTitle: string;
    @Input() shareDescription: string;
    @Input() shareUrl: string;
    @Input() shareUrlSms: string;
    @Input() fundraiserId: number;
    @Input() isLaunchathon: number;
    @Input() orderTransactionId: string;

    @Output() returnToFundraiserHomeEmitter: EventEmitter<any> = new EventEmitter<any>();

    customerMessage: string = '';
    showMessageContainer: boolean = true;

    public aPayContent = '';
    public aPayEmbeddedVideo = '';

    constructor(
        injector: Injector,
        private _sharedOrdersServiceProxy: SharedOrdersServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getAPayContent();
        this.getAPayEmbeddedVideo();
    }

    returnToFundraiserHome() {
        this.returnToFundraiserHomeEmitter.emit(null);
    }

    getAPayContent() {
        this._sharedOrdersServiceProxy.getAPayContentByOrderTransactionId(this.orderTransactionId).subscribe((aPayContent) => {
            this.aPayContent = aPayContent;
        });
    }

    getAPayEmbeddedVideo() {
        this._sharedOrdersServiceProxy.getAPayEmbeddedVideo(this.fundraiserId).subscribe((aPayEmbeddedVideoContentString) => {
            this.aPayEmbeddedVideo = aPayEmbeddedVideoContentString;
        });
    }

    setOrderMessage(submitMessage: boolean) {
        // we'll POST to the server regardless:
        // even if there's no message, we still want to know,
        // so that we can updatae the livelink page,
        // if it's a launchathon fundraiser.
        if (!submitMessage) {
            this.customerMessage = '';
        }
        this._sharedOrdersServiceProxy.setOrderMessage(this.orderTransactionId, this.customerMessage).subscribe(() => {
            if (this.customerMessage) {
                this.notify.info(this.l('SavedSuccessfully'));
            }
            this.showMessageContainer = false;
        });
    }

}